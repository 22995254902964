import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from './service/api.service';
import { BlogData } from './service/model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  ListBlog: BlogData[] = [];
  detailBlog: BlogData;
  constructor(
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    private dbService: ApiService,
  ) { }

  ngOnInit(): void {
    this.loadMenu();
  }
  async loadMenu() {
    this.spinnerWithoutBackdrop = true
    this.dbService.getItems().subscribe((res) => {
      this.spinnerWithoutBackdrop = false;
      res.forEach(element => {
        this.ListBlog.push({
          id: element.id,
          tgl: new Date(parseInt(element.id)).toLocaleDateString("en-US"),
          judul: element.judul,
          isi: element.isi,
        })
      });
    })
  }
}
