import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListProduct, MenuProduct } from '../home/home/model';

@Component({
  selector: 'app-productkategori',
  templateUrl: './productkategori.component.html',
  styleUrls: ['./productkategori.component.scss']
})
export class ProductkategoriComponent implements OnInit {
  catRef: AngularFireList<ListProduct>;
  itemCat: Observable<ListProduct[]>;
  namaRef: AngularFireList<MenuProduct>;
  mainDB = environment.mainDb;
  namaKategori: string;
  subKategori: string;
  productid: string;
  ListKategori: ListProduct[] = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  constructor(
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    const kategoriId = this.route.snapshot.params.category;
    const productId = this.route.snapshot.params.productid;
    this.subKategori = kategoriId;
    this.productid = productId;
    this.loadMenu(productId, kategoriId);
  }

  async loadMenu(pid, katid) {
    this.spinnerWithoutBackdrop = true
    await this.loadNama(pid);
    await this.loadKategori(pid);
    this.itemCat.subscribe((res) => {
      this.spinnerWithoutBackdrop = false;
      res.forEach((item) => {
        if (item.contentCategory.toLowerCase() === katid.toLowerCase()) {
          this.ListKategori.push(item);
        }
      });
    })
  }
  loadKategori(pid) {
    this.catRef = this.db.list(this.mainDB + 'kategori/' + pid + '/produk');
    this.itemCat = this.catRef.snapshotChanges().pipe(map(change => {
      return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
    }));
  }

  loadNama(pid) {
    this.namaRef = this.db.list(this.mainDB + 'kategori/' + pid);
    this.namaRef.snapshotChanges().subscribe((res) => {
      res.forEach((item) => {
        if (item.key === 'nama') {
          this.namaKategori = item.payload.val().toString();
        }
      })

    })
  }
}
