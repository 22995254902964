import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database/';
import { AngularFireStorage } from '@angular/fire/storage/';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DisplayCategory, MenuData, SlideModel } from '../home/model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  itemsRef: AngularFireList<MenuData>;
  downloadURL: Observable<string>;
  items: Observable<MenuData[]>;
  slideRef: AngularFireList<SlideModel>;
  itemSlide: Observable<SlideModel[]>;
  displayRef: AngularFireList<DisplayCategory>;
  itemDisplay: Observable<DisplayCategory[]>;
  mainDB = environment.mainDb;
  constructor(public storage: AngularFireStorage,
    private db: AngularFireDatabase,) {
    this.itemsRef = db.list(this.mainDB + 'laporan');
    this.items = this.itemsRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
  }

  getItems(): Observable<any> {
    return this.items
  }

  getSlide(): Observable<SlideModel[]> {
    this.slideRef = this.db.list(this.mainDB + 'slide');
    return this.itemSlide = this.slideRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }));
  }
  getDisplay(): Observable<DisplayCategory[]> {
    this.displayRef = this.db.list(this.mainDB + 'categoryDisplay');
    return this.itemDisplay = this.displayRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }));
  }
}
