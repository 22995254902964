import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
// import { NguCarousel, NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import 'hammerjs'
import { interval, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { ApiService } from '../service/api.service';
import { slider } from '../../slideAnimations';
import { IBannerCarouselImage } from '@nghacks/banner-carousel';
import { DisplayCategory } from './model';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  ListDisplay: any[] = [];
  images: any[] = [];
  public carouselTileItems$: Observable<number[]>;
  mainDB = environment.mainDb;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  constructor(
    private dbService: ApiService,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    private route: Router,
  ) { }

  ngOnInit(): void {

    this.loadImage();
  }

  showDetail(id) {
    this.route.navigateByUrl('/slide/' + id);
  }

  async loadImage() {
    this.spinnerWithoutBackdrop = true;
    await this.dbService.getSlide().subscribe((res) => {
      res.forEach((item) => {
        this.spinnerWithoutBackdrop = false;
        this.images.push(
          {
            src: item.image[0].url.toString(),
            id: item.id,
          }
        );
      });
    });
    await this.dbService.getDisplay().subscribe((res) => {
      this.ListDisplay = res;
    });

  }


  pilih(item) {
    this.route.navigateByUrl('/content/' + item.nama);
  }
}
