import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SlideData } from '../home/home/model';

@Component({
  selector: 'app-slidedetail',
  templateUrl: './slidedetail.component.html',
  styleUrls: ['./slidedetail.component.scss']
})
export class SlidedetailComponent implements OnInit {
  catRef: AngularFireObject<SlideData>;
  mainDB = environment.mainDb;
  slide: SlideData;
  slideId: string;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  imagePick: string;
  constructor(
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.slideId = this.route.snapshot.params.slideid;
    this.loadMenu();
  }

  async loadMenu() {
    this.spinnerWithoutBackdrop = true
    await this.loadKategori();
  }
  loadKategori() {
    this.catRef = this.db.object(this.mainDB + 'slide/' + this.slideId);
    this.catRef.snapshotChanges().subscribe((res) => {
      this.slide = new SlideData(res.payload);
      // this.imagePick = this.ListKategori?.image[0].url;
      // this.katid = this.ListKategori?.contentCategory;
    });
  }

}
