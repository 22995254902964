<div class="container">
    <!-- <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [backdropEnabled]="true"
        *ngIf="spinnerWithoutBackdrop">
    </mat-progress-spinner> -->
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex=100>

        <div fxLayout="row " fxFlex=70 class="content-top" fxLayoutAlign="start center">
            <div class="info-section">SLIDE > {{slide.nama}} </div>
        </div>
        <div fxLayout="column " fxFlex=80 class="content-display" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="space-evenly center">
                <div>
                    <img [src]="slide.image[0].url" fxFlex=100 />
                </div>
                <div class="garis"></div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="center center" fxLayoutGap="5px">
                <div class="title-detail">{{slide?.nama |
                    uppercase}}</div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="space-evenly center">
                <div>
                    <div class="isi-detail" [innerHTML]="slide?.deskripsi"></div>
                </div>
            </div>
        </div>
    </div>
</div>