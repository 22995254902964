import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BlogData } from '../service/model';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  itemsDetail: AngularFireObject<any>;
  details: Observable<BlogData>;
  mainDB = environment.mainDb;
  blogid: string;
  hasilBlog: any;
  constructor(
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
  ) { }

  ngOnInit(): void {
    this.blogid = this.route.snapshot.params.id;

    this.getDetail();
  }
  getDetail() {
    this.itemsDetail = this.db.object(this.mainDB + 'blog/' + this.blogid);
    this.itemsDetail.snapshotChanges().subscribe((res) => {
      this.hasilBlog = new BlogData(res);
    });

  }
}
