<div class="container">
    <!-- <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [backdropEnabled]="true"
        *ngIf="spinnerWithoutBackdrop">
    </mat-progress-spinner> -->
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex=100>

        <div fxLayout="row " fxFlex=70 class="content-top" fxLayoutAlign="start center">
            <div class="info-section">PRODUCT > {{namaKategori}} > {{subKategori}}</div>
        </div>
        <div fxLayout="row wrap" fxFlex=80 class="content-display" fxLayoutAlign="center center">
            <div fxLayout="row wrap" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="space-evenly center">

                <div fxLayout="column" class="img-box" fxFlex=30 fxLayoutGap="15px"
                    *ngFor="let item of ListKategori; let i= index">
                    <a href="product/detail/{{productid}}/{{item.id}}">
                        <div>
                            <img [src]="item.image[0].url" fxFlex=100 />
                        </div>
                        <div class="title-harga">{{item.harga | currency:1:'Rp '}}</div>
                        <div class="title">{{item.nama}}</div>
                        <div class="garis"></div>
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>