<div class="container">
    <!-- <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [backdropEnabled]="true"
        *ngIf="spinnerWithoutBackdrop">
    </mat-progress-spinner> -->
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex=100>

        <div fxLayout="row " fxFlex=70 class="content-top" fxLayoutAlign="start center">
            <div class="info-section">Blog > List</div>
        </div>
        <div fxLayout="column" fxFlex=80 class="content-display" fxLayoutAlign="space-evenly center">
            <div fxLayout="row wrap" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="start center"
                *ngFor="let item of ListBlog; let i= index" class="box-blog">
                <a href="blog/{{item.id}}">
                    <div class="title">{{item.judul}}</div>
                    <div class="sub-title">{{item.tgl | date:'dd/MM/yyyy'}}</div>
                    <div class="isi-detail" [innerHTML]="item?.isi"></div>
                </a>
            </div>
        </div>
    </div>
</div>