import { DataSnapshot, SnapshotAction } from "@angular/fire/database/interfaces";


export interface MenuData {
  id: string;
  category: string;
  image: string;
  filename: string;
  produk: any;
  publish: boolean;

}


export interface ProdukData {
  id: string;
  nama: string;
  sub: string;
  keterangan: string;
  ingredients: string;
  image: any;
  satuan: string;
  ukuran: string;
  harga: number;
  hargaReseller: number;
  disc: number;
  publish: any;
  stock: number;
  supplier: string;
  ukuranKurir: number;
  tag: TagProduk[];
  contentCategory: CategoryModel;
  subCategory: SubCategory;
  sex: string;
}
export interface CategoryModel {
  id?: string;
  nama?: string;
  image?: string;
  filename?: string;
  publish?: boolean;
  tag?: TagContent[];
}
export interface TagContent {
  id?: string;
  namaTag?: string;
}

export interface SubCategory {
  id?: string;
  nama?: string;
}
export interface TagProduk {
  id?: string;
  namaTag?: string;
}

export interface SlideModel {
  id?: string;
  deskripsi?: string;
  image?: any[];
  nama?: string;
  type?: string;
  videour?: string;
}

export interface MenuProduct {
  id?: string;
  nama?: string;
  subCategory?: any;
  produk?: ListProduct[];
}

export class ListProduct {
  id?: string;
  contentCategory?: string;
  disc?: number
  harga: number;
  hargaReseller: number;
  image?: any;
  keterangan?: string;
  nama?: string;
  publish?: boolean;
  satuan?: string;
  sex?: string;
  stock?: number;
  sub?: string;
  subCategory?: string;
  tag?: any;
  ukuran?: number;
  ukuranKurir?: number;
  constructor(data?: any
  ) {
    this.id = data.key,
      this.contentCategory = data.val().contentCategory,
      this.disc = data.val().disc,
      this.harga = data.val().harga,
      this.hargaReseller = data.val().hargaReseller,
      this.image = data.val().image,
      this.keterangan = data.val().keterangan,
      this.nama = data.val().nama,
      this.publish = data.val().publish,
      this.satuan = data.val().satuan,
      this.sex = data.val().sex,
      this.stock = data.val().stock,
      this.sub = data.val().sub,
      this.subCategory = data.val().subCategory,
      this.tag = data.val().tag,
      this.ukuran = data.val().ukuran,
      this.ukuranKurir = data.val().ukuranKurir
  }
}


export class ListProductNew {
  catId?: string;
  id?: string;
  contentCategory?: string;
  disc?: number
  harga: number;
  hargaReseller: number;
  image?: any;
  keterangan?: string;
  nama?: string;
  publish?: boolean;
  satuan?: string;
  sex?: string;
  stock?: number;
  sub?: string;
  subCategory?: string;
  tag?: any;
  ukuran?: number;
  ukuranKurir?: number;
  constructor(catid: string, id: string, data?: ListProduct
  ) {
    this.catId = catid,
      this.id = id,
      this.contentCategory = data.contentCategory,
      this.disc = data.disc,
      this.harga = data.harga,
      this.hargaReseller = data.hargaReseller,
      this.image = data.image,
      this.keterangan = data.keterangan,
      this.nama = data.nama,
      this.publish = data.publish,
      this.satuan = data.satuan,
      this.sex = data.sex,
      this.stock = data.stock,
      this.sub = data.sub,
      this.subCategory = data.subCategory,
      this.tag = data.tag,
      this.ukuran = data.ukuran,
      this.ukuranKurir = data.ukuranKurir
  }
}

export interface DisplayCategory {
  id?: string;
  filename?: string;
  image?: string;
  nama?: string;
  publish?: boolean;
}

export class SlideData {
  id?: string;
  deskripsi?: string;
  image?: any;
  nama?: string;
  videourl?: string;
  constructor(data?: any
  ) {
    this.id = data.key,
      this.deskripsi = data.val().deskripsi,
      this.image = data.val().image,
      this.nama = data.val().nama,
      this.videourl = data.val().videourl
  }
}