import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MenuProduct } from './home/home/model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  formCari: FormGroup;
  title = 'pilihmana';
  catRef: AngularFireList<MenuProduct>;
  itemCat: Observable<MenuProduct[]>;
  mainDB = environment.mainDb;
  ListMenu: any[] = [];
  pencarian: any = {};
  constructor(
    private route: Router,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
  ) {
    this.formCari = new FormGroup({
      cari: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {

    this.loadMenu();
  }
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
  async loadMenu() {
    await this.loadKategori();
    this.itemCat.subscribe((res) => {
      this.ListMenu = res;
    })
  }
  loadKategori() {
    this.catRef = this.db.list(this.mainDB + 'kategori');
    this.itemCat = this.catRef.snapshotChanges().pipe(map(change => {
      return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
    }));
  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.route.navigateByUrl('/pencarian/' + this.formCari.get('cari').value);
    }
  }
  doCari() {
    this.route.navigateByUrl('/pencarian/' + this.formCari.get('cari').value);
  }
}
