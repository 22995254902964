<div class="container">
    <!-- <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [backdropEnabled]="true"
        *ngIf="spinnerWithoutBackdrop">
    </mat-progress-spinner> -->
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex=100>

        <div fxLayout="row " fxFlex=80 class="content-top" fxLayoutAlign="start center">
            <div class="info-section">PRODUCT > {{ListKategori?.contentCategory | uppercase}} > {{ListKategori?.nama |
                uppercase}}</div>
        </div>
        <div fxLayout="row wrap" fxFlex=80 class="content-display">
            <div fxLayout="row wrap" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="space-between start"
                fxLayoutGap="5px">
                <div fxLayout="column" fxLayout.xs="row" fxLayout.md="row" fxLayout.sm="row" class="box-left" fxFlex=8
                    *ngFor="let item of ListKategori?.image; let i= index" fxLayoutAlign="start center">
                    <div fxLayout="row" (click)="imageShow(i)" *ngIf="ListKategori?.image.length > 1 "><img
                            [src]="item.url" /></div>
                </div>
                <div fxLayout="column" class="box-mid" fxFlex=65>
                    <div fxLayout="row" fxLayout.xs="row" fxLayout.md="row" fxLayout.sm="row" fxFlex=100
                        fxLayoutAlign="center center"><img [src]="imagePick" fxFlex=100 /></div>
                </div>
                <div fxLayout="column" class="box-right" fxFlex=24>
                    <div fxLayout="row" class="title" fxFlex=100>{{ListKategori?.nama |
                        uppercase}}</div>
                    <div fxLayout="row" class="title-harga" fxFlex=100>{{ListKategori?.harga |
                        currency:0:'Rp '}}</div>
                    <div fxLayout="row" class="tombol-beli" fxFlex=100 fxLayoutAlign="center center" (click)="buy()">
                        BELI</div>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="center center" fxLayoutGap="5px">
                <div class="title-detail">{{ListKategori?.nama |
                    uppercase}}</div>
            </div>
            <div fxLayout="row wrap" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="center center" fxLayoutGap="5px">
                <div class="isi-detail" [innerHTML]="ListKategori?.keterangan"></div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="center center" fxLayoutGap="5px">
                <div class="content-top"></div>
            </div>

            <div fxLayout="row wrap" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" fxFlex=80
                fxLayoutAlign="start center" fxLayoutGap="5px">
                <div class="title-terkait">PRODUK DALAM KATEGORI INI</div>
            </div>

            <div class="overflow-carousel-page">
                <div class="image-box">
                    <overflow-carousel [slidePercentage]="1">

                        <button slide-left-button mat-raised-button class="left-btn">
                            <mat-icon>navigate_before</mat-icon>
                        </button>

                        <carousel-item *ngFor="let item of ListProd" class="image-card">
                            <a href="product/detail/{{kategoriId}}/{{item.id}}"><img [src]=" item.image[0].url"
                                    width="100%" height="auto">
                                <!-- <div id="harga"> {{ListKategori?.nama |
                                uppercase}}</div> -->
                                <div id="harga"> {{ListKategori?.harga |
                                    currency:0:'Rp '}}</div>
                            </a>
                        </carousel-item>

                        <button slide-right-button mat-raised-button class="right-btn">
                            <mat-icon>navigate_next</mat-icon>
                        </button>

                    </overflow-carousel>
                </div>
            </div>
        </div>
    </div>
</div>