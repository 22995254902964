<div class="container">
    <!-- <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [backdropEnabled]="true"
        *ngIf="spinnerWithoutBackdrop">
    </mat-progress-spinner> -->
    <!-- <app-progress-spinner class="example-margin" [color]="color" [mode]="mode" [value]="value" [backdropEnabled]="true"
        [displayProgressSpinner]="spinnerWithoutBackdrop">
    </app-progress-spinner> -->
    <div fxLayout="colum wrap" fxLayoutAlign="center" fxFlex=100>
        <!-- <banner-carousel *ngIf="images.length > 0 " (imageClick)="onimageClick($event)"
                (imageChange)="onimageChange($event)" [height]="600" [autoChangeInterval]="5000" [data]="images">
            </banner-carousel> -->
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let item of images; let i= index">
                <div>
                    <img src="{{item.src}}" alt="item.id" (click)="showDetail(item.id)">
                </div>
                <!-- <div class="carousel-caption">
                        <!-- <h3>Title Goes Here</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div> -->
            </ng-template>
            <!-- <ng-template ngbSlide>
                  <div class="picsum-img-wrapper">
                    <img src="assets/img-02.jpeg" alt="Angular Carousel 2">
                  </div>
                  <div class="carousel-caption">
                    <h3>Title Goes Here</h3>
                    <p>Consectetur tortor volutpat pretium.</p>
                  </div>
                </ng-template> -->
        </ngb-carousel>
        <div fxLayout="row wrap" fxFlex.lg=65 fxFlex.md=100 fxFlex.sm=100 fxFlex.xs=100 fxLayout.md="column"
            fxLayout.sm="column" class="content-display" fxLayoutAlign="space-between center">
            <div class="img-box" fxLayout="row wrap" fxLayoutGap="5px" fxLayout.xs="column" fxFlex=100>
                <img [src]="item.image" fxFlex=49 fxLayoutGap="5px" height="auto" fxLayout.md="column"
                    *ngFor="let item of ListDisplay; let i= index" (click)="pilih(item)" />
            </div>
        </div>
    </div>
</div>