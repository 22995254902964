import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListProduct, ListProductNew, MenuProduct } from '../home/home/model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  catRef: AngularFireList<MenuProduct>;
  itemPro: Observable<MenuProduct[]>;
  mainDB = environment.mainDb;
  ListCari: ListProductNew[] = [];
  searchcontent: string;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  constructor(
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.searchcontent = '';
    this.searchcontent = this.route.snapshot.params.search;
    this.loadMenu();
  }

  loadProduct() {
    this.catRef = this.db.list(this.mainDB + 'kategori/');
    this.itemPro = this.catRef.snapshotChanges().pipe(map(change => {
      return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
    }));
  }

  async loadMenu() {
    this.spinnerWithoutBackdrop = true
    await this.loadProduct();
    this.itemPro.subscribe((res) => {
      res.forEach((item) => {
        for (var x in item.produk) {
          if (item.produk[x].nama.toLowerCase().includes(this.searchcontent)) {
            this.ListCari.push(new ListProductNew(item.id, x, item.produk[x]));
          }
        }
      });
    })
  }

}
