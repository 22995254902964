<div class="toolbar" role="banner">
  <button mat-icon-button fxHide fxShow.xs fxShow.md fxShow.sm>
    <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
  </button>
  <img width="40" alt="Angular Logo" src="../assets/logo.png" />
  <span style="letter-spacing: 0.1em;">Produk123</span>
  <div fxLayout="row" fxFlex=80 fxLayoutAlign="space-between center" class="nav" fxShow fxHide.xs fxHide.md fxHide.sm>
    <ul>
      <li><a href="/">Home</a></li>
      <li><a>Product</a>
        <ul>
          <li *ngFor="let item of ListMenu; let i = index">
            <div id="title"><a href="/kategori/{{item.id}}">{{item.nama}}</a></div>
            <div id="child" *ngFor="let ct of item.subCategory; let a = index"><a
                href="product/{{item.id}}/{{ct.nama}}">{{ct.nama}}</a></div>
          </li>
        </ul>
      </li>
      <li><a href="blog">Blog</a></li>
      <li>
        <form [formGroup]="formCari" class="example-form" (keydown)="keyDownFunction($event)">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Pencarian</mat-label>
            <input type="text" matInput placeholder="Pencarian" name="cari" formControlName="cari">
            <mat-icon matSuffix (click)="doCari()">search</mat-icon>
          </mat-form-field>
        </form>
      </li>
    </ul>

  </div>
  <a aria-label="Angular on twitter" target="_blank" rel="noopener" href="" title="Twitter Pilih mana sports">
    <svg id="twitter-logo" height="24" data-name="Logo" xmlns="" viewBox="0 0 400 400">
      <rect width="400" height="400" fill="none" />
      <path
        d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"
        fill="#fff" />
    </svg>
  </a>
</div>


<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <mat-nav-list style="width: 80vw;">
      <a mat-list-item [routerLink]="'/'"> HOME </a>
      <a mat-list-item [matMenuTriggerFor]="menu"> PRODUCT </a>
      <mat-menu #menu="matMenu" style="width: 80vw;">
        <li *ngFor="let item of ListMenu; let i = index">
          <div id="title"><a href="/kategori/{{item.id}}">{{item.nama}}</a></div>
          <div id="child" *ngFor="let ct of item.subCategory; let a = index"><a
              href="product/{{item.id}}/{{ct.nama}}">{{ct.nama}}</a></div>
        </li>
      </mat-menu>
      <a mat-list-item href="/blog"> BLOG </a>
      <a mat-list-item (click)="sidenav.toggle()" href="" mat-list-item>TUTUP</a>
      <!-- <mat-list-item >
        <mat-icon>unfold_more</mat-icon>
        <a matline>Example</a>
      </mat-list-item>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>View profile</button>
        <button mat-menu-item>Add contact</button>
      </mat-menu> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content style="height: 100vh;" class="content-wrapper">
    <div class="content">

      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>