import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListProduct, MenuProduct } from '../home/home/model';

@Component({
  selector: 'app-productdetail',
  templateUrl: './productdetail.component.html',
  styleUrls: ['./productdetail.component.scss']
})
export class ProductdetailComponent implements OnInit {
  catRef: AngularFireObject<ListProduct>;
  proRef: AngularFireList<ListProduct>;
  itemPro: Observable<ListProduct[]>;
  mainDB = environment.mainDb;
  kategoriId: string;
  productId: string;
  ListKategori: ListProduct;
  ListProd: ListProduct[] = [];
  katid: string;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  imagePick: string;
  constructor(
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.kategoriId = this.route.snapshot.params.category;
    this.productId = this.route.snapshot.params.productid;
    this.loadMenu();
  }

  async loadMenu() {
    this.spinnerWithoutBackdrop = true
    await this.loadKategori();
    this.combinePro();


  }
  loadKategori() {
    this.catRef = this.db.object(this.mainDB + 'kategori/' + this.kategoriId + '/produk/' + this.productId);
    this.catRef.snapshotChanges().subscribe((res) => {
      this.ListKategori = new ListProduct(res.payload);
      this.imagePick = this.ListKategori?.image[0].url;
      this.katid = this.ListKategori?.contentCategory;
    });
  }
  loadProduct() {
    this.proRef = this.db.list(this.mainDB + 'kategori/' + this.kategoriId + '/produk/');
    this.itemPro = this.proRef.snapshotChanges().pipe(map(change => {
      return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
    }));
  }
  async combinePro() {
    await this.loadProduct();
    this.itemPro.subscribe((res) => {
      res.forEach((item) => {
        if (item.contentCategory.toLowerCase() === this.katid.toLowerCase() && item.id !== this.productId) {
          this.ListProd.push(item);
        }
      });
    })
  }
  buy() {
    // this.openSnackBar('Comming soon !!!', 'Information')
    window.open("https://play.google.com/store/apps/details?id=com.itmudah.tokosale", "_blank")
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }
  imageShow(i) {
    this.imagePick = this.ListKategori?.image[i].url;
  }

}
