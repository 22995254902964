<div class="container">
    <!-- <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [backdropEnabled]="true"
        *ngIf="spinnerWithoutBackdrop">
    </mat-progress-spinner> -->
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex=100>

        <div fxLayout="row " fxFlex=70 class="content-top" fxLayoutAlign="start center">
            <div class="info-section">Blog > Detail > {{hasilBlog?.judul}}</div>
        </div>
        <div fxLayout="column" fxFlex=80 class="content-display" fxLayoutAlign="space-evenly center">
            <div fxLayout=" row" fxLayout.xs="column" fxFlex=100 fxLayoutAlign="start center" class="box-blog">
                <div class="title" fxFlex=100>{{hasilBlog?.judul}}</div>
                <div class="sub-title" fxFlex=100>{{hasilBlog?.tgl | date:'dd/MM/yyyy'}}</div>
                <div class="isi-detail" [innerHTML]="hasilBlog?.isi"></div>
            </div>

        </div>
    </div>
</div>