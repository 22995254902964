import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule, } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule, } from '@angular/material/datepicker';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AppOverlayModule } from '../overlay/overlay.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { RouterModule } from '@angular/router';
import { BannerCarouselModule } from '@nghacks/banner-carousel';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SlidedetailComponent } from "./slidedetail.component";

const routes = [
  {
    path: 'slide/:slideid',
    component: SlidedetailComponent
  }
];

@NgModule({
  declarations: [
    SlidedetailComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    HttpClientModule,
    MatNativeDateModule,
    HttpClientJsonpModule,
    MatDatepickerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, BrowserAnimationsModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule, FormsModule, ReactiveFormsModule, MatInputModule, MatSnackBarModule,
    MatProgressSpinnerModule,
    AppOverlayModule,
    ProgressSpinnerModule,
    BannerCarouselModule,
    FlexLayoutModule
  ]
})
export class SlidedetailModule { }
