export class BlogData {
    id?: string;
    judul?: string;
    isi?: string;
    tgl?: string;
    constructor(
        data: any
    ) {
        this.id = data.key;
        this.judul = data.payload.val().judul;
        this.isi = data.payload.val().isi;
        this.tgl = new Date(parseInt(data.key)).toLocaleDateString("en-US");

    };
}