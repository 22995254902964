import { Injectable, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database/';
import { AngularFireStorage } from '@angular/fire/storage/';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BlogData } from './model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  itemsRef: AngularFireList<BlogData>;
  downloadURL: Observable<string>;
  items: Observable<BlogData[]>;
  mainDB = environment.mainDb;
  constructor(public storage: AngularFireStorage,
    private db: AngularFireDatabase,) {
    this.itemsRef = db.list(this.mainDB + 'blog');
    this.items = this.itemsRef.snapshotChanges()
      .pipe(map(change => {
        return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
      }))
  }

  getItems(): Observable<any> {
    return this.items
  }


}
