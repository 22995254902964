import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListProduct, MenuProduct } from '../home/home/model';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  catRef: AngularFireList<ListProduct>;
  itemCat: Observable<ListProduct[]>;
  namaRef: AngularFireList<MenuProduct>;
  mainDB = environment.mainDb;
  ListProduct: MenuProduct[] = [];
  namaKategori: string;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  kategoriId: string;
  constructor(
    private route: ActivatedRoute,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.kategoriId = this.route.snapshot.params.kategoriId;
    this.loadMenu();
  }

  async loadMenu() {
    this.spinnerWithoutBackdrop = true
    await this.loadNama();
    await this.loadKategori();
    this.itemCat.subscribe((res) => {
      this.spinnerWithoutBackdrop = false;
      this.ListProduct = res;
    })
  }
  loadKategori() {
    this.catRef = this.db.list(this.mainDB + 'kategori/' + this.kategoriId + '/produk');
    this.itemCat = this.catRef.snapshotChanges().pipe(map(change => {
      return change.map(c => ({ id: c.payload.key, ...c.payload.val() }));
    }));
  }

  loadNama() {
    this.namaRef = this.db.list(this.mainDB + 'kategori/' + this.kategoriId);
    this.namaRef.snapshotChanges().subscribe((res) => {
      res.forEach((item) => {
        if (item.key === 'nama') {
          this.namaKategori = item.payload.val().toString();
        }
      })

    })
  }
}
